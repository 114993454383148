<template>
  <!-- Error page-->
  <div class="misc-wrapper">

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center"> 
        <h2 class="mb-1 mt-3">
          Hemos actualizado nuestros             
          <a href="https://sosty.co/terminos-y-condiciones/" target="_blank">
              <span>&nbsp;Términos y Condiciones </span>
          </a>          
        </h2>

        <h4 class="mb-1 mt-3">
          Hola, hemos realizado algunos cambios a nuestros           
          <a href="https://sosty.co/terminos-y-condiciones/" target="_blank">
              <span>&nbsp;Términos y Condiciones </span>
          </a>   
          que reflejan algunas mejoras que hemos realizado en Sosty.        
        </h4>

        <h2 class="mb-1 mt-3">
              ¿Qué ha cambiado?
        </h2>

        <h4 class="mb-1 mt-3">
              Hemos actualizado nuestros Términos y Condiciones generales por Términos y Condiciones Generales de la plataforma Sosty, Términos y condiciones del Portal de Contacto de Proyectos Productivos Sosty  y Términos y Condiciones para los E-Services de Asesoría y gestión Agropecuaria de Sosty S.A.S.. Lo que nos permite facilitar las transacciones realizadas a través de nuestra plataforma con aceptación de contratos digitales o e-services, incrementando la seguridad para los proyectos de Sosty tanto para nuestros Productores como para los Neoganaderos.
        </h4>

        <b-button label-align-sm="left"  variant="primary"  @click="AcceptTermsAndConditions">He leído y acepto los nuevos términos y condiciones.</b-button>
        <b-img class="mt-3"
            fluid
            :src="imgUrl"
            alt="Login V2"
        />
      </div>
    </div>
  </div>

</template>


<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg, BSpinner,BRow, BCol } from 'bootstrap-vue'
import store from '@/store/index'
import axios from '@axios'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser, getAbilitiesBasedOnUserType } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'


export default {
  components: {        
      BLink,
      BButton,
      BImg,
      BSpinner,
      BRow,
      BCol
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/sosty-login.png'),
      redirectToProjectCode: null,
      email: null,
      user:{},
      }
  },
  created() {
  },

  computed: {
      imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {            
          this.downImg = require('@/assets/images/pages/sosty-login.png')
          return this.downImg
      }
      return this.downImg
      },
  },

  methods:{
    AcceptTermsAndConditions(){
        var userData = getUserData();
        axios.get('/User/AcceptTermsAndConditions', { params: { userID: userData.userID}})
        .then(response => {            
          localStorage.setItem('userData', JSON.stringify(response.data.user));
          this.$router.replace('/');
        })
    }, 
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
